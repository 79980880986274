<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-xl-6 col-lg-12">
          <div class="row pt-1">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <div class="row">
                      <div class="col-xl-6">
                        <span>{{ $t("dashboard.mytodo") }}</span>
                      </div>
                      <div class="col-xl-6 text-right pr-2">
                        <font-awesome-icon
                          icon="fa-solid fa-plus-circle"
                          style="cursor: pointer"
                          @click="neueAktivitaet"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-12 block belegliste br-t-l-0">
                        <b-table
                          ref="todoTable"
                          tbody-tr-class="item"
                          style="height: 285px"
                          responsive
                          sticky-header
                          :busy="pendenzenIsBusy"
                          show-empty
                          :items="aktivitaeten"
                          :fields="fields"
                          @row-dblclicked="aktivitaetOeffnen"
                        >
                          <template #table-busy>
                            <div class="text-center text-primary my-2">
                              <b-spinner class="align-middle"></b-spinner>
                            </div>
                          </template>

                          <template #empty>
                            <div
                              v-if="pendenzenLadeFehler"
                              class="text-center text-danger my-2"
                            >
                              <strong>{{
                                $t("global.errorwhileloading")
                              }}</strong>
                            </div>
                            <div
                              v-if="!pendenzenLadeFehler"
                              class="text-center text-primary my-2"
                            >
                              <strong>{{
                                $t("dashboard.therearenopendingtodos")
                              }}</strong>
                            </div>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-2 col-lg-12">
          <div class="row pt-1" v-if="berechtigungen.m_lead.read">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>{{ $t("dashboard.leadsbystage") }}</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-12">
                        <column-chart
                          :data="statistikLeadsByStage"
                          height="300px"
                          prefix="CHF"
                          thousands="'"
                          :colors="[
                            '#add58c',
                            '#1ad7e1',
                            '#ade0ec',
                            '#c3c4e2',
                            '#fccb9d',
                            '#f4f3d5',
                          ]"
                          :legend="false"
                        ></column-chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-12">
          <div class="row pt-1" v-if="berechtigungen.m_anmeldungen.read">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>Leads nach Abschlussdatum</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-12">
                        <line-chart
                          :data="statistikLeadsByMonth"
                          height="300px"
                          :legend="false"
                          prefix="CHF"
                          thousands="'"
                          :colors="[
                            '#add58c',
                            '#1ad7e1',
                            '#ade0ec',
                            '#c3c4e2',
                            '#fccb9d',
                            '#f4f3d5',
                          ]"
                        ></line-chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-1">
        <!-- LEFT COLUMN -->

        <div class="col-xl-2 col-lg-12">
          <div class="row pt-1" v-if="berechtigungen.m_geschaeftspartner.read">
            <!-- CRM -->
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>{{ $t("dashboard.crm") }}</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="tile-frame left-aligned h-1">
                          <router-link to="/geschaeftspartner/personenliste">
                            <div class="tile bg-med-green inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-user-friends"
                                  fixed-width
                                />
                              </div>
                              <div class="title">
                                <div>{{ $t("dashboard.contacts") }}</div>
                              </div>
                              <div class="informations">
                                <div class="row">
                                  <div
                                    class="col-xl-8 col-lg-8 col-md-8 col-8"
                                  ></div>
                                  <div class="col-xl-4 col-lg-4 col-md-4 col-4">
                                    <router-link
                                      to="/stammdaten/mitarbeitende/"
                                    >
                                      <div class="information fs-16">
                                        <font-awesome-layers class="fa-lg">
                                          <font-awesome-icon
                                            icon="fa-solid fa-circle"
                                            transform="down-2"
                                          />
                                          <font-awesome-icon
                                            icon="fa-solid fa-people-arrows"
                                            transform="shrink-6 left-1 down-2"
                                            :style="{ color: 'white' }"
                                          />
                                        </font-awesome-layers>

                                        <span class="fs-14 ml-1">{{
                                          dashboardInfo.mitarbeitende
                                        }}</span>
                                      </div></router-link
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-12">
                        <div class="tile-frame left-aligned h-1">
                          <router-link to="/geschaeftspartner/firmenliste">
                            <div class="tile bg-med-green inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-building"
                                />
                              </div>
                              <div class="title">
                                <div>{{ $t("dashboard.companies") }}</div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Lead Management -->

        <div class="col-xl-2 col-lg-12">
          <div class="row pt-1">
            <!-- CRM -->
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>Leadmanagement</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-12" v-if="berechtigungen.m_lead.read">
                        <div class="tile-frame left-aligned h-1">
                          <router-link :to="{ name: 'lead-liste' }">
                            <div class="tile bg-med-green inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-regular fa-tachometer-alt"
                                />
                              </div>
                              <div class="title">
                                <div>Leads</div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>

                      <div class="col-xl-6" v-if="berechtigungen.m_lead.read">
                        <div class="tile-frame left-aligned h-1">
                          <router-link :to="{ name: 'aktivitaet-liste' }">
                            <div class="tile bg-light-green inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-solid fa-alarm-clock"
                                />
                              </div>
                              <div class="title">
                                <div>{{ $t("dashboard.activities") }}</div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>

                      <div
                        class="col-xl-6"
                        v-if="berechtigungen.m_kampagne.read"
                      >
                        <div class="tile-frame left-aligned h-1">
                          <router-link :to="{ name: 'kampagne-liste' }">
                            <div class="tile bg-light-green inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-regular fa-chart-gantt"
                                />
                              </div>
                              <div class="title">
                                <div>Kampagne</div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Finanzen -->

        <div class="col-xl-4 col-lg-12">
          <div class="row pt-1" v-if="berechtigungen.m_finanzen.read">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>{{ $t("dashboard.finance") }}</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-6 col-lg-12 col-md-6">
                        <div class="tile-frame left-aligned h-1">
                          <router-link :to="{ name: 'auftraege' }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-file-invoice-dollar"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.invoices") }}
                              </div>
                              <div class="informations">
                                <div class="row">
                                  <div class="col-xl-6"></div>
                                  <div class="col-xl-6">
                                    <div class="information fs-16">
                                      <font-awesome-layers class="fa-lg">
                                        <font-awesome-icon
                                          icon="fa-solid fa-circle"
                                          transform="down-2"
                                        />
                                        <font-awesome-icon
                                          icon="fa-solid fa-star-half-stroke"
                                          transform="shrink-6 left-1 down-2"
                                          :style="{ color: 'white' }"
                                        />
                                      </font-awesome-layers>

                                      <span class="fs-14 ml-1">{{
                                        dashboardInfo.invoicedraft
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-xl-6"></div>
                                  <div class="col-xl-6">
                                    <div class="information fs-16">
                                      <font-awesome-layers class="fa-lg">
                                        <font-awesome-icon
                                          icon="fa-solid fa-circle"
                                          transform="down-2"
                                        />
                                        <font-awesome-icon
                                          icon="fa-duotone fa-coins"
                                          transform="shrink-6 left-1 down-2"
                                          :style="{ color: 'white' }"
                                        />
                                      </font-awesome-layers>

                                      <span class="fs-14 ml-1">{{
                                        dashboardInfo.openbalance
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-12 col-md-6">
                        <div class="tile-frame left-aligned h-1">
                          <router-link :to="{ name: 'artikelliste' }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-shopping-basket"
                                  transform="shrink-2 left-1"
                                />
                              </div>
                              <div class="title">Artikel</div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-3 col-lg-3 col-md-12">
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'Debitoren' }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-money-bill"
                                  transform="left-1 shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.debtors") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-12">
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'mahnungen' }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-shield-exclamation"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.reminders") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-12">
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'Zahlungen' }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-wallet"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.payments") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>

                      <div class="col-xl-3 col-lg-3 col-md-12">
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'Belege' }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-receipt"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.accountingentries") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- KOMMUNIKATION -->

        <div class="col-xl-4 col-lg-12">
          <!-- Korrespondenz -->

          <div class="row pt-1" v-if="berechtigungen.m_korrespondenz.read">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>{{ $t("dashboard.communication") }}</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'korrespondenz-liste' }">
                            <div class="tile bg-turkis inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-print"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.correspondence") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'mail-liste' }">
                            <div class="tile bg-turkis inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-envelope-open-text"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.mails") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="col-xl-6 col-lg-6 col-md-6"
                        v-if="berechtigungen.m_dokumente.read"
                      >
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'Dokumentenliste' }">
                            <div class="tile bg-pink inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-file-upload"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.documents") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div
                        class="col-xl-6 col-lg-6 col-md-6"
                        v-if="berechtigungen.m_notizen.read"
                      >
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'notizen-liste' }">
                            <div class="tile bg-pink inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-comments-alt"
                                  transform="shrink-4 left-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.notes") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-2 col-lg-12">
          <!-- Ressourcen -->

          <div class="row pt-1" v-if="berechtigungen.m_ressourcen.read">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>{{ $t("dashboard.resources") }}</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-4col-lg-4 col-md-4">
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'Terminliste' }">
                            <div class="tile bg-bright-orange inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-calendar-day"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.dates") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-4col-lg-4 col-md-4">
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'termin-kalender' }">
                            <div class="tile bg-bright-orange inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-calendar-alt"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.calendar") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>

                      <div
                        class="col-xl-4col-lg-4 col-md-4"
                        v-if="berechtigungen.m_bildschirm.read"
                      >
                        <div class="tile-frame centered h-1">
                          <router-link :to="{ name: 'bildschirm-planer' }">
                            <div class="tile bg-bright-orange inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-tv-alt"
                                  transform="left-2 shrink-3"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.screens") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-2 col-lg-12">
          <!-- Zeiterfassung -->

          <div class="row pt-1" v-if="berechtigungen.m_timereporting.read">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>{{ $t("dashboard.timereporting") }}</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-xl-4col-lg-4 col-md-4">
                        <div class="tile-frame centered h-1">
                          <router-link to="/zeiterfassung/zeitblaetter">
                            <div class="tile bg-bright-orange inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-calendar-day"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.timesheets") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-4col-lg-4 col-md-4">
                        <div class="tile-frame centered h-1">
                          <router-link to="/zeiterfassung/uebersicht">
                            <div class="tile bg-bright-orange inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-calendar-day"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.monthsheet") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-4col-lg-4 col-md-4">
                        <div class="tile-frame centered h-1">
                          <router-link to="/zeiterfassung/projektuebersicht">
                            <div class="tile bg-bright-orange inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-calendar-day"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">
                                {{ $t("dashboard.projectoverview") }}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";

import { LOAD_DASHBOARD_INFO } from "@/store/dashboard/actions.type";
import { apiErrorToAlert } from "@/utils/Errorhandler";

export default {
  store,
  data() {
    return {
      pendenzenIsBusy: false,
      pendenzenLadeFehler: false,
      aktivitaeten: [],
      fields: [
        {
          key: "geplantdatum",
          sortable: true,
          label: "Geplant am",
          formatter: (value, key, item) => {
            return value ? value + " - " + item.geplantzeit : "-";
          },
        },

        {
          key: "typ.bezeichnung",
          sortable: true,
          label: this.$t("dashboard.type"),
        },

        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.contactperson"),
        },

        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
      ],
      sortBy: "geplantdatum",
      sortDesc: false,
      statistikLeadsByStage: null,
      statistikLeadsByType: null,
      statistikLeadsByMonth: null,
    };
  },
  computed: {
    dashboardInfo: {
      get() {
        return this.$store.state.dashboard.dashboardInfo;
      },
    },
  },
  created() {
    if (!this.angemeldeterMitarbeiter)
      Api.get("aktueller_mitarbeiter/").then((response) => {
        this.angemeldeterMitarbeiter = response.data;

        Api.get("/crm/aktivitaet/", {
          params: {
            resp: this.angemeldeterMitarbeiter.mitarbeiter.person.id,
            status: this.$CONST("CONFIG").AKTIVITAETSTATUSGEPLANT,
          },
        }).then((response) => {
          this.aktivitaeten = response.data;
        });
      });

    /* Statistik für Anmeldungen */

    Api.get("/statistik/", { params: { type: "leadsbystage" } }).then(
      (response) => {
        let tempList = response.data;
        this.statistikLeadsByStage = [];
        let stages = {};

        tempList.forEach((item) => {
          if (!stages[item[0]]) stages[item[0]] = 0;

          stages[item[0]] += item[1];
        });

        this.statistikLeadsByStage = stages;
      }
    );

    Api.get("/statistik/", { params: { type: "leadsbytype" } }).then(
      (response) => {
        let tempList = response.data;
        this.statistikLeadsByType = [];
        let types = {};

        tempList.forEach((item) => {
          if (!types[item[0]]) types[item[0]] = 0;

          types[item[0]] += item[1];
        });

        this.statistikLeadsByType = types;
      }
    );

    Api.get("/statistik/", { params: { type: "leadsbymonth" } }).then(
      (response) => {
        let tempList = response.data;
        this.statistikLeadsByMonth = [];
        let months = {};

        tempList.forEach((item) => {
          let thisDate = new Date(item[0]);
          if (thisDate) {
            let thisMonth =
              thisDate.getDate() +
              "." +
              (thisDate.getMonth() + 1) +
              "." +
              thisDate.getFullYear();

            if (!months[thisMonth]) months[thisMonth] = 0;

            months[thisMonth] += item[1];
          }
        });

        this.statistikLeadsByMonth = months;
      }
    );

    /* Infoboxen für Frontend */

    if (this.dashboardInfo.mitarbeitende == 0)
      this.$store.dispatch(`dashboard/${LOAD_DASHBOARD_INFO}`);
  },
  mounted() {},
  methods: {
    getAktivitaeten() {
      this.pendenzenIsBusy = true;
      Api.get("crm/aktivitaet/", {
        params: {
          resp: this.angemeldeterMitarbeiter.mitarbeiter.person.id,
          status: this.$CONST("CONFIG").AKTIVITAETSTATUSGEPLANT,
        },
      })
        .then((response) => {
          this.aktivitaeten = response.data;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.pendenzenLadeFehler = true;
        })
        .finally(() => {
          this.pendenzenIsBusy = false;
        });
    },

    aktivitaetOeffnen(aktivitaet) {
      this.$bus.$emit("aktivitaet-modal-show", aktivitaet);
    },

    neueAktivitaet() {
      let json = {
        //verantwortlicher: this.angemeldeterMitarbeiter.person,
        verantwortlicher: this.$store?.state?.mitarbeiter?.person,
        person: this.ansprechpartner,
        neu: true,
      };
      this.$bus.$emit("aktivitaet-modal-show", json);
    },

    aktivitaetErstellt(aktivitaet) {
      console.log("aktivitaetErstellt");

      let json = aktivitaet;
      json.lead = this.lead;

      Api.post("crm/aktivitaet/", json)
        .then(() => {
          this.getAktivitaeten();
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: "Aktivitaet erfolgreich gespeichert.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    aktivitaetGeaendert(aktivitaet) {
      console.log("aktivitaetGeaendert");

      let json = aktivitaet;
      json.lead = this.lead;

      Api.put("crm/aktivitaet/", json, { params: { id: aktivitaet.id } })
        .then(() => {
          this.getAktivitaeten();
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: "Aktivitaet erfolgreich gespeichert.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
</style>
